<script>
import DynamicProducts from '../components/DynamicProducts'

export default {
  components: {
    DynamicProducts,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  inject: ['product'],
  computed: {
    title() {
      return this.block.fields.Title;
    },
    relationshipType() {
      return this.block.fields.RelationshipType?.value;
    },
    numOfProducts() {
      return this.block.fields.NumberOfProducts || 0;
    },
    products() {
      return this.product.relatedProducts[this.relationshipType] || [];
    },
    marginTop() {
      return this.block.fields.MarginTop || 0;
    },
    marginBottom() {
      return this.block.fields.MarginBottom || 0;
    },
    showRelatedProducts() {
      return this.products.length > 0;
    },
    limitedProducts() {
      return this.numOfProducts ? this.products.slice(0, this.numOfProducts) : this.products;
    },
  },
};
</script>

<template>
  <DynamicProducts
    v-if="showRelatedProducts"
    :title="title"
    :products="limitedProducts"
    :margin-top="marginTop"
    :margin-bottom="marginBottom"
  ></DynamicProducts>
</template>
